import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Stores from "../components/Stores"
import Promo from "../components/Promo"
import Helmet from "react-helmet"
import icon from "../images/ALEYA - ICON FINAL (BLACK).png"

const Page = ({ data }) => {
  if (!data) return null
  const document = data.prismic.allLanding_pages.edges.slice(0, 1).pop()

  return (
    <>
        <Helmet
        title={document.node.meta_title}
        link={[{ rel: "icon", type: "image/png", href: icon }]}
        meta={[
            {
            name: `description`,
            content: document.node.meta_description,
            },
            {
            property: `og:title`,
            content: document.node.meta_title,
            },
            {
            property: `og:description`,
            content: document.node.meta_description,
            },
            {
            property: `og:type`,
            content: `website`,
            },
            {
            property: `og:image`,
            content: document.node.og_image.url,
            },
        ]}
        />
        <Layout>
            <Promo>{document.node.tagline}</Promo>
            <Stores links={document.node.links} />
        </Layout>
    </>
  )
}

export const query = graphql`
  query PageQuery($uid: String) {
    prismic {
        allLanding_pages(uid: $uid) {
          edges {
            node {
              _meta {
                uid
              }
              tagline
              meta_title
              meta_description
              og_image
              links {
                  image
                  link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  enabled
                }
            }
          }
        }
      }
  }
`

export default Page